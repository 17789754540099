<template>
    <div class="dash-container">
        <h1>
        CONTACTS
        </h1>
        
        <hr style="margin: 5px 0px;border: 1px solid #e3e3e3;">
        <div>
        <p v-if='message' class="error-message">{{ this.emsg }}</p> 
        <b-table class="contacts" 
          striped 
          default-sort-direction="asc" 
          default-sort="name" pack="fa" 
          sort-icon="arrow-down" 
          sort-icon-size="is-small"
          paginated
          per-page="10"
          :data="data"
        >
          <template v-for="column in columns">
            <b-table-column :key="column.id" v-bind="column">
                <template
                    v-if="column.searchable && !column.numeric"
                    slot="searchable"
                    slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        style="padding-left: 0px;"
                        placeholder="Search..."
                        size="is-small" />
                </template>
                <template v-slot="props">
                    {{ props.row[column.field] }}
                    <div v-if="column.field === 'remove'">
                      <button class='button mini-button is-primary is-outlined is-danger' 
                        :id='props.index' 
                        alt="Deny" 
                        v-on:click="removeContact(props.row.id, props.index)"
                      >
                        <b-icon pack="fa" icon="remove">
                        </b-icon>
                      </button>
                    </div>
                </template>
            </b-table-column>
          </template>
        </b-table>
        </div>
    </div>
</template>
<script>

export default {
    data() {
      return {
        smsg: "",
        emsg: "",
        data: [],
        columns: [
          {
              field: 'name',
              label: 'Name',
              width: 100,
              searchable: true,
              sortable: true,
          },
          {
              field: 'email',
              label: 'E-Mail',
              width: 100,
              searchable: true,
          },
          {
              field: 'lock',
              label: 'Lock',
              width: 70,
          },
          {
              field: 'device',
              label: 'Device',
              width: 50,
          },
          {
              field: 'remove',
              label: 'Remove',
              width: 60,
              data: "<div v-html='<b-icon pack='fa' icon='user'></b-icon>' />"
          }
        ]
      }
    },
    watch: {
    '$props':{
      handler: function (val, oldVal) { 
        console.log('watch', val);
        console.log('watch', oldVal);
        this.message="";
        this.getData();
      },
      deep: true
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      requestAnimationFrame(() => {
        var api = process.env.VUE_APP_API;
        var query = this.q;
        console.log("API: " + api);
        console.log("Searching: " + query);
        this.loading = true;

        this.$store.dispatch('auth/get_contacts', query).then(
        (response) => {
            console.log(response);
            if (response.data.status == true) {
                console.log("if loop: ");
                console.log(response.data.data);
                this.data = response.data.data;
            } else {
              this.message = "You have no requests..."
              console.log("You have no requests");
              this.data = response.data.data;
            }
        },
        error => {
            this.loading = false;
            this.data = [];
            this.message = "You have no requests...";
            console.log(error);
        });

      });
    },
    removeContact(uid, row) {
        this.smsg = "";
        this.emsg = "";
        requestAnimationFrame(() => {
        var api = process.env.VUE_APP_API;
        console.log("API: " + api);
        console.log("Denying: " + uid);
        this.loading = true;

        this.$store.dispatch('auth/remove_req', uid).then(
        (response) => {
            console.log(response.status);
            if (response.status == true) {
                this.smsg = "Removed";
                var d = document.getElementById(row)
                d.innerHTML="<span>Removed</span>";
                d.disabled = true;
            } else {
              this.emsg = "Server Error...";
              console.log("Server Error...");
            }
        },
        error => {
            this.emsg = "Server Error...";
            console.log(error);
        });
        this.loading = false;
      });

    }
  }
}
</script>
<style lang='scss' scoped>
h1 {
    font-weight: bold;
}
.contacts {
  padding-top: 30px;
}
.mini-button {
  padding: 10px;
  margin-left: 5px;
  height: 15px;
}
</style>